<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from "@components/input/AkInputText";
import AkFormView from "@components/layout/AkFormView";

/* MIXINS */
import randomRef from "@mixins/randomRef";

/* SERVICES */
import pwdService from '@services/pwdService';

export default {
  components: {AkInputText, AkFormView},
  mixins: [randomRef],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "",
    }
  },
  data() {
    return {
      submitted: false,
      messages: [],
      pwd0: '',
      pwd1: '',
      pwd2: '',
    }
  },
  validations() {
    return {
      pwd0: {required},
      pwd1: {required},
      pwd2: {required},
    }
  },
  methods: {
    changePwd() {
      this.submitted = true;
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      if (this.pwd1 !== this.pwd2) {
        this.getRef().error(this.$t("reset_pwd_err_confirm"));
        return;
      }
      if (!this.isValidPwd(this.pwd1)) {
        this.getRef().error(this.$t("reset_pwd_err_invalid"));
        return;
      }

      //change pwd
      pwdService.changePwd({pwd0: this.pwd0, pwd1: this.pwd1, pwd2: this.pwd2}).then(() => {
        // Redirect to the originally requested page, or to the home page
        this.$router.push(this.$route.query.redirectFrom || {name: 'home'})
      }).catch(error => {
        let data = error.response.data;
        this.getRef().error(this.$t(data.error))
      });
    },
    isValidPwd(pwd) {
      return pwd.match(/\d/g) && pwd.match(/[A-Z]/g) && pwd.match(/[a-z]/g) && pwd.match(/[^a-zA-Z\d]/g) && pwd.length >= 10;
    },
    isValidMinCar() {
      return this.pwd1.match(/[a-z]/g);
    },
    isValidMajCar() {
      return this.pwd1.match(/[A-Z]/g);
    },
    isValidNumberCar() {
      return this.pwd1.match(/\d/g);
    },
    isValidSpecialCar() {
      return this.pwd1.match(/[^a-zA-Z\d]/g);
    }
  },
}
</script>

<template>
  <!-- begin app-wrap -->
  <div class="app-wrap">
    <!--start login contant-->
    <div class="app-contant">
      <div class="container">
        <div class="row justify-content-center align-items-center h-100-vh">
          <div class="col-lg-4">
            <div class="d-flex align-items-center">
              <div class="login pt-4">
                <img alt="Logo DiRIF" class="logo-idx mb-4" src="@assets/logo_dirif.png"/>
                <AkFormView :ref="ref" :title="$t('changepwd_title')" :displayGoBack=false class-title="col-12">
                  <template v-slot:form>
                    <div class="pwd-grid">
                      <span :class="{'invalid':!this.isValidMinCar(), 'valid':this.isValidMinCar()}">1 caractére minuscule</span>
                      <span :class="{'invalid':!this.isValidMajCar(), 'valid':this.isValidMajCar()}">1 caractére majuscule</span>
                      <span
                          :class="{'invalid':!this.isValidNumberCar(), 'valid':this.isValidNumberCar()}">1 chiffre</span>
                      <span :class="{'invalid':!this.isValidSpecialCar(), 'valid':this.isValidSpecialCar()}">1 caractére spécial</span>
                      <span :class="{'invalid':!this.pwd1.length<10, 'valid':this.pwd1.length>=10}">10 caractéres minimun</span>

                    </div>
                    <form v-on:submit.prevent="changePwd">
                      <div class="row">
                        <AkInputText
                            v-model="pwd0"
                            :submitted=submitted
                            :label="$t('user.password_previous')"
                            inputType="password"
                            :validator="v$.pwd0"
                            class-name="col-md-12"/>
                        <AkInputText
                            v-model="pwd1"
                            :submitted=submitted
                            :label="$t('user.password_new')"
                            inputType="password"
                            :validator="v$.pwd1"
                            class-name="col-md-12"/>
                        <AkInputText
                            v-model="pwd2"
                            :submitted=submitted
                            :label="$t('user.password_repeat')"
                            inputType="password"
                            :validator="v$.pwd2"
                            class-name="col-md-12"/>
                        <div class="col-12 mt-3">
                          <button class="btn btn-primary float-right text-uppercase">{{
                              $t("reset_pwd_perform")
                            }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </template>
                </AkFormView>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-none d-md-block">
            <img alt="" class="img-fluid" src="@assets/saleuse.jpg" style="max-height:100vh">
          </div>
        </div>
      </div>
    </div>
    <!--end login contant-->
  </div>
  <!-- end app-wrap -->
</template>